<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="user-top">
          <b-card-body>
            <div class="user-text">
              <img class="user-icon" src="@/assets/images/icons/user-request.png" alt="">
              <span class="user-title">
                  {{$t("Access Requests")}}
              </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="offset-1 col-10">
            <b-table
              :items="permissionUserList"
              :fields="fields"
              striped
              responsive
              class="user-list mb-0"
            >
              <template #cell(avatar)="data">
                <b-avatar :src="data.value" />
              </template>
              <template #cell(approval)="data">
                <div class="icons-list">
                  <b-button
                      class="approval-btn"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="none"
                      @click=openModalRole(data.item)
                  >
                    <feather-icon
                        class="approval-icon"
                        :icon="data.value"
                    />
                  </b-button>
                </div>
              </template>
              <template #cell(delete)="data">
                <div class="icons-list">
                  <b-button
                      class="approval-btn"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-center-delete
                      variant="none"
                      @click=openModalDelete(data.item)
                  >
                    <feather-icon
                        class="delete-icon"
                        :icon="data.value"
                    />
                  </b-button>

                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <template #code>
        {{ codeRowDetailsSupport }}
      </template>
    </b-row>

    <!--ROLE MODAL-->
        <b-modal
        id="modal-center-role"
        ref="modal-center-role"
        ok-only
        centered
        :ok-title="$t('Save')"
        class="justify-content-center"
        @ok="approveRequest"
    >
      <span class="modal-title">{{$t("Define User Permission")}}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
          <validation-observer ref="modalRole">
            <b-form-group>
              <validation-provider
                            #default="{}"
                            name="Check Confirm"
                            rules="required"
                            v-model="checkList"
                          >
              <b-form-checkbox
                  class="check-role"
                  v-model="checkList"
                  name="checkbox-1"
                  value="admin"
                  :disabled="condition"
              >
              <span class="check-role">{{$t("Admin")}}</span>
              </b-form-checkbox>
              <small class="text-description">{{ $t("Total Control") }}</small>
              <b-form-checkbox
                  class="check-role"
                  v-model="checkList"
                  name="checkbox-1"
                  value="client"
                  :disabled="condition"
              >
              <span class="check-role">{{$t("User")}}</span>
              </b-form-checkbox>
              <small class="text-description">{{ $t("Edit and visualize data. Cannot manage users") }}</small>

              <b-form-checkbox
                  class="check-role"
                  v-model="checkList"
                  name="checkbox-1"
                  value="client_remote"
                  :disabled="condition"
              >
              <span class="check-role">{{$t("User Remote")}}</span>
              </b-form-checkbox>

              <small class="text-description">{{ $t("Control remote actions. Cannot manage users") }}</small>
              <b-form-checkbox
                  class="check-role"
                  v-model="checkList"
                  name="checkbox-1"
                  value="tech"
                  :disabled="condition"
              >
              <span class="check-role">{{$t("Technician")}}</span>
              </b-form-checkbox>          
              <small class="text-description">{{ $t("View data. Cannot edit data or manage users") }}</small>
              <b-form-checkbox
                  class="check-role"
                  v-model="checkList"
                  name="checkbox-1"
                  value="tech_remote"
                  :disabled="condition"
              >
              <span class="check-role">{{$t("Technician Remote")}}</span>
              </b-form-checkbox>          
              <small class="text-description">{{ $t("Control remote actions. Cannot edit data or manage users") }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>


    <!--DELETE MODAL-->
    <b-modal
        id="modal-center-delete"
        ref="modal-center-delete"
        ok-only
        centered
        :ok-title="$t('Save')"
        class="justify-content-center"
        @ok="denyRequest"
    >
      <span class="modal-title">{{$t("Access Requests")}} (deny)</span>
      <b-row>
        <b-col cols="12" class="mt-1">
          <p>{{$t("Do you want to deny the access request of:")}} <br> <b>{{userData.full_name}}</b></p>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BTable, BFormCheckbox, BAvatar, BBadge, BModal, VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { codeRowDetailsSupport } from './code'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from "@validations";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BModal,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [{ key: 'avatar', label: '' }, {key: 'full_name', label: this.$t('NAME')}, {key: 'email', label: this.$t('EMAIL')}, { key: 'device_name', label: this.$t('DEVICE') }, { key: 'approval', label: this.$t('APPROVAL') }, { key: 'delete', label: this.$t('DELETE') }],
      codeRowDetailsSupport,
      userData: {},
      checkList: "",

      //validation
      required
    }
  },
  methods: {
    openModalRole(data){
      this.userData=data;
      this.$refs['modal-center-role'].show()
    },
    openModalDelete(data){
      this.userData=data;
      this.$refs['modal-center-delete'].show()
    },
      approveRequest()
      {
        if(this.checkList == ""){
          this.error = "Please, select one checkbox"
          this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'User Delete Failed',
                        icon: 'AlertTriangleIcon',    
                        text: this.error,
                        variant: 'danger'
                        },
                    })
        }
        else{
          let request_id = this.userData.id
          this.$store.dispatch("users/postSolveRequest", { context: this, id:request_id, result:true, role:this.checkList})
          .then(() => 
          {
              this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'User Approved',
                        icon: 'RefreshCwIcon',
                        variant: 'success',
                      },
                    });
              this.userData = {}
          })
          .catch( error => 
          {
              this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: this.$t('Error'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text:  error.data
                      },
                    }) 
                this.userData = {}
          });
        }
      },
    denyRequest()
      {
        let request_id = this.userData.id
        this.$store.dispatch("users/postSolveRequest", { context: this, id:request_id, result:false })
        .then(() => 
        {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User Denied',
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  });
            this.userData = {}
        })
        .catch( error => 
        {
             this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('Error'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: error.data
                    },
                  }) 
              this.userData = {}
        });
      },
    isNewRequest(value)
    {
      return value.closed_at == null && value.approved == null
    }
  },
  computed:{
    permissionUserList(){
      let list_permission = this.$store.state.users.permissionUserList
      list_permission.forEach(user => {
        user["full_name"] = user.first_name + ' ' + user.last_name  
        user["approval"] = 'CheckCircleIcon'
        user["delete"] = 'Trash2Icon'
      });
      return list_permission.filter(this.isNewRequest)
    },
    condition() {
      return false
    }
  },
  created(){
    this.$store.dispatch("users/getPermissionUserList", { context: this, status:null});
  },
}
</script>
<style scoped>

.text-description{
  font-size: 12px;
  color: #6e707e;
  margin-bottom: 0px;
  margin-left: 20px;
}

.check-role{
  font-family: "TitilliumWeb-regular";
  font-size: 19px;
  font-weight: bold;
  color: #5e5873;
}
</style>

