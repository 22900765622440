<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12" xl="12">
        <b-card no-body class="user-top">
          <b-card-body>
            <div class="user-text">
              <feather-icon class="user-icon" icon="UserIcon" />
              <span class="user-ativo"> {{$t("Approved Users")}} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="offset-1 col-10">
            <b-table
              :items="approvedUserList"
              :fields="fields"
              striped
              responsive
              class="user-list mb-0"
              style="min-height: 400px"
            >
              <template #cell(avatar)="data">
                <b-avatar :src="data.value" />
              </template>
              <template #cell(role)="{
                            item,
                            field: { key },index
                        }">
                <v-select
                  :placeholder="item[key] ?item[key] : 'Role'"
                  label="label"
                  :reduce="(role) => role"
                  @input="selectedRole(item,index,$event)"
                  class="status-select"
                  :searchable="false"  
                  :options="listGroups"
                  attach
                >
                  <template
                  v-slot:selected-option="option"
                >
                  {{ option.label }}
                </template>
                <template v-slot:option="option">
                  {{ option.label }}
                </template>
                </v-select>
              </template>
               <template #cell(confirm)="data">
                <div class="icons-list">
                  <b-button
                      class="approval-btn"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="none"
                      @click=openModalConfirm(data.item)
                  >
                    <feather-icon
                        class="approval-icon"
                        :icon="data.value"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <template #code>
        {{ codeRowDetailsSupport }}
      </template>
       <!--APPROVAL MODAL-->
      <b-modal
        id="modal-center-approval"
        ref="modal-center-approval"
        ok-only
        centered
        ok-title="Confirm"
        class="justify-content-center"
        @ok="approveRequest()"
    >
      <span class="modal-title">{{$t("Confirm Role Change")}}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
         <p>{{$t("Would you like to change the user Role to:")}} <br> <b>{{this.userData.roleSelected ?  this.userData.roleSelected.label : ""}}</b></p>
        </b-col>
      </b-row>
    </b-modal>
    </b-row>
  </div>
  
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { codeRowDetailsSupport } from "./code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: "avatar", label: "" },
        { key: "full_name", label:this.$t("NAME") },
        { key: "device", label: this.$t("DEVICE") },
        { key: "role", label: this.$t("ROLE")},
        { key: 'confirm', label: this.$t('CONFIRM') }
      ],
      /* eslint-disable global-require */
      codeRowDetailsSupport,
      userData: {},

      listGroups:[
        {value:"admin",label:"Admin"},
        {value:"client",label:"User"},
        {value:"tech",label:"Technician"},
        {value:"client_remote",label:"User Remote"},
        {value:"tech_remote",label:"Technician Remote"}

      ]
    };
  },
  methods: {
    openModalConfirm(data){
      this.userData=data;
      this.$refs['modal-center-approval'].show()
    },
    isApproved(value) {
      return value.closed_at != null && value.approved == true;
    },
    approveRequest()
    {
      let data = {
        user:this.userData.user_id,
        role:this.userData.roleSelected.value,
        request_user:this.userData.request_id
      }
      this.$store.dispatch('users/postJoinGroup', { context: this, data})
      .then(res => 
      {
          this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'User Approved',
                    icon: 'RefreshCwIcon',
                    variant: 'success',
                    text: res.data
                  },
                });
          this.userData = {}
      })
      .catch( error => 
      {
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Error'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: error.data
                  },
                }) 
            this.userData = {}
      });
    },
    selectedRole(data, index, event)
    {
      data.roleSelected = event ? {value:event.value,label:event.label} : ""
      return data
    }
  },
  computed: {
    approvedUserList() {
      let user_list = this.$store.state.users.activeUserList;
      user_list.forEach((user) => {
        this.listGroups.forEach((group) => {
          if (user.role  == group.value) {
            user["role"] = group.label;
          }
        });
        user["confirm"] = 'CheckCircleIcon'
        user["roleSelected"] = ""
      });
      return user_list;
    }
  },
  created()
  {
    this.$store.dispatch("users/getUserList", { context: this});
  }
};
</script>